import React          from 'react'
import Typography     from 'entrada-ui/Typography'
import { FaPhoneAlt }    from 'react-icons/fa'
import Link           from 'components/Link'
import styles         from './styles.module.scss'

const PhoneContactBlock = () => {
  
  return (
      <div className={styles.wrap}>
        <Typography variant="h3" color="brand-color-2">
          <FaPhoneAlt className={styles.phone} /> 
          <a className={styles.phoneLink} href="tel:0800486877">0800 486 877</a>
        </Typography>

        <Typography variant="body" color="white">
          Questions? Call us on our tollfree number and our friendly team can help.
        </Typography>

        <Link className="btn-secondary d-inline-block mt-3" to={'/contact-us'}>
          Get in touch
        </Link>
      </div>
  )
}

export default PhoneContactBlock
