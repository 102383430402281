import React                from 'react'
import Img                  from "gatsby-image"
import { map }              from 'lodash-es'
import { FaClock }          from 'react-icons/fa'
import {
  Container,
}                           from 'react-bootstrap'
import Alert                from 'react-bootstrap/Alert'
import RawHtml              from 'entrada-ui/RawHtml'
import Banner               from 'components/Banner'
import BookContact          from 'components/BookContact'
import Link                 from 'components/Link'
import TourCards            from 'components/TourCards'
import Seo                  from 'components/Seo'
import PhoneContactBlock    from 'components/PhoneContactBlock'
import PageTitle            from 'components/PageTitle'
import styles               from './styles.module.scss'

import { FIXED_FRAGMENT, FLUID_FRAGMENT } from 'utils/constants.js'

const TourCard = TourCards.CardWp

const Destination = ({ pageContext: { data } }) => {
  const {
    siteMetadata,
    title,
    content,
    acf: {
      desktop_image: {
        localFile: {
          childImageSharp: {
            fluid
          }
        }
      },
      related_tours
    },
    yoast_meta
  } = data


  return (
    <>
      <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
      <PageTitle title={title} isDestination={true} />
      <Container className={styles.containerSmallNoGutter}>
        <Img fluid={fluid} className={styles.heroImage} />
      </Container>
      
      <Container className={styles.containerListing}>
      
        <RawHtml html={content} />
      
        <TourCards aside={true}>
          {map(related_tours, (tour, idx) => (
            <TourCard
              key={idx}
              {...tour}
            />
          ))}
        </TourCards>

        <PhoneContactBlock />

      </Container>

    </>
  )
}

export default Destination
