import React                from 'react'
import cx                   from 'classnames'
import { FaPhoneAlt }          from 'react-icons/fa'
import Typography           from 'entrada-ui/Typography'
import styles               from './styles.module.scss'

const BookContact = ({ className, big = false }) => (
  <div className={cx(styles.bookContact, big && styles.big, className)}>
    {big ?
    <>
      <Typography className="d-flex align-items-center" variant="h4" color="brand-color-2">
        <FaPhoneAlt className={styles.bookContactIcon} /> <a href="tel:0800486877" className={styles.bookContactLink}>0800 486 877</a>
      </Typography>
    </>
    :
    <>
      <FaPhoneAlt className={styles.bookContactIcon} /> Book online or call: <a href="tel:0800486877">0800 486 877</a>
    </>

    }
  </div>
)

export default BookContact
